import {SelectItem} from "../models/select-item.model";
import {EnumUtils} from "./EnumUtils";

export enum IndexPrimerPlate {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D'
}

export namespace IndexPrimerPlate {

  export function getIndexPrimerPlateReadable(indexPrimerPlate: IndexPrimerPlate): string {
    if (!indexPrimerPlate) {
      return '';
    }
    switch (indexPrimerPlate) {
      case IndexPrimerPlate.A:
        return 'A';
      case IndexPrimerPlate.B:
        return 'B';
      case IndexPrimerPlate.C:
        return 'C';
      case IndexPrimerPlate.D:
        return 'D';
      default:
        return '';
    }
  }

  export function getAll(): IndexPrimerPlate[] {
    return  EnumUtils.getAll<IndexPrimerPlate>(IndexPrimerPlate, 'string');
  }

  export function getEnumByValue(value: string): IndexPrimerPlate {
    return EnumUtils.getEnumByValue<IndexPrimerPlate>(IndexPrimerPlate, 'string', value);
  }

  export function getAllAsSelectItem(): SelectItem[] {
    return IndexPrimerPlate.getAll().map(indexPrimerPlate => ({id: indexPrimerPlate, name: IndexPrimerPlate.getIndexPrimerPlateReadable(indexPrimerPlate)}));
  }

  export function getByIndex(index: number): string {
    return EnumUtils.getAll<IndexPrimerPlate>(IndexPrimerPlate, 'string')[index];
  }

  export function getPreviousPlateSuggestion(indexPrimerPlate: IndexPrimerPlate): IndexPrimerPlate {
    const currentIndex = getAll().indexOf(indexPrimerPlate);
    const previousIndex = (currentIndex - 1 + getAll().length) % getAll().length;
    return getAll()[previousIndex];
  }

}
